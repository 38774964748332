.appheader {
	background: #fff;
	height: 100px;
	padding-top: 10px;
	padding-bottom: 10px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	.logo {
		width: 120px;
		height: 100%;

		padding: 5px;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}
