.quizView {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;

	margin-top: 30px;
}
.quizSideBar {
	background: rgba($color: #ffffff, $alpha: 0);

	.content {
		width: 100%;
		margin-top: 50px;
	}
}
.quizPost {
	background: rgba($color: #ffffff, $alpha: 0);

	padding: 20px;
}
.question {
	padding-bottom: 20px;

	header {
		h3 {
			font-size: 0.9rem;
			color: grey;
		}
	}

	.content {
		padding: 15px;
		background-color: #fff;

		h2 {
			margin: 0;
			font-size: 1.2rem;
		}

		.images {
			margin-top: 20px;
			width: 100%;
			display: flex;
			justify-content: flex-start;

			img {
				max-width: calc(50% - 20px);
			}
		}
	}
}
.answers {
	ul {
		background-color: #fff;
		padding: 0;
		width: 100%;
		display: flex;
		flex-direction: column;

		li {
			list-style: none;

			border-top: 1px solid lightgrey;

			&:first-child {
				border-top: none;
			}

			.answerOption {
				width: 100%;
				display: flex;
				flex-direction: row;
				gap: 50px;
				align-items: center;

				cursor: pointer;
				padding: 20px;

				background-color: rgba($color: #aaaaaa, $alpha: 0);
				transition: 0.2s linear;

				.radio {
					svg {
						transform: translateY(2px);
					}
				}

				&.choosen {
					background-color: rgba($color: #aaaaaa, $alpha: 0.3);

					.radio {
						svg {
							fill: green;
						}
					}
				}

				&:hover {
					background-color: rgba($color: #aaaaaa, $alpha: 0.5);
				}

				.text {
					font-size: 1rem;
					font-weight: 500;
				}

				.images {
					margin-top: 10px;
					img {
						max-width: 300px;
					}
				}
			}
		}
	}
}

.watch {
	width: 100%;
	height: 150px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: grey;
	border: 2px dashed lightgrey;

	@media (max-width: 480px) {
		margin-bottom: 15px;
		height: 100px;
	}

	.clock {
		font-size: 2.2rem;
		font-weight: 600;
		color: grey;
		margin: 0;

		&.red {
			color: red;
		}
	}
}
.submitAnswer {
	margin: 15px 0;
	width: 100%;

	button {
		height: 50px;
		font-family: var(--ff);
		font-weight: 500;
	}
}

.session__waiting {
	width: 100%;
	height: calc(100vh - 170px);

	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	.watch {
		margin-bottom: 35px;
	}

	.message {
		.detail {
			display: flex;
			flex-direction: column;
			align-items: center;

			button {
				margin-top: 40px;
				max-width: 250px;
			}
		}
	}
}
.uploader {
	width: 100%;

	height: 100px;

	padding: 20px;
	border: 2px dashed lightgrey;

	cursor: pointer;

	input {
		display: none;
	}
	.files {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 30px;

		svg {
			transform: scale(2);
		}

		.hint {
			width: 100%;
			text-align: center;
			color: grey;

			p,
			small {
				margin: 0;
			}
		}
	}
}
.upcoming {
	transform: scale(0.8);
	text-align: center;
}
