.homeView {
	width: 100%;
	min-height: 100vh;
	padding-bottom: 30px;

	.content {
		width: 100%;
		max-width: 700px;
		margin: 0 auto;
		padding-top: 20px;

		display: flex;
		flex-direction: column;
		align-items: center;

		img {
			width: 300px;
		}

		.information {
			text-align: center;
			margin: 20px 0;
			background-color: #fff;

			h2 {
				padding: 20px 0;

				background: #000;
				color: white;
			}
			ol {
				text-align: justify;
				padding: 0;
				padding: 10px 40px;

				li {
					line-height: 1.8;
					margin: 15px 0;
				}
				ul {
					li {
						list-style: disc;
						font-weight: 500;
					}
				}
			}
		}
	}
}
